import React from "react";
import "./LoadScreen.css";

export default function LoadScreen({ isLoading }) {
  return (
    <div
      className={`load-screen ${
        isLoading ? "load-screen_visible" : "load-screen_hidden"
      }`}
    >
      <svg
        width="50%"
        height="50%"
        viewBox="0 0 188 67"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="logo"
      >
        <path
          d="M164.037 64.8306H175.819C182.302 64.8306 187.374 61.3974 187.374 56.6309C187.374 52.7419 183.599 48.738 178.184 48.3947V48.2427C182.035 47.7468 184.209 45.0776 184.209 42.9043C184.209 39.434 180.205 37.1465 174.256 37.1465H164.037V37.4893H165.486C167.507 37.4893 167.736 37.6801 167.736 39.5106V62.1601C167.736 63.9907 167.507 64.1809 165.486 64.1809H164.037V64.8306ZM170.291 63.8758V63.7998C170.787 63.3422 170.977 62.732 170.977 61.8551V39.854C170.977 38.9764 170.787 38.3286 170.291 37.8709V37.8326H173.684C177.878 37.8326 180.624 39.8917 180.624 42.9802C180.624 45.9929 177.955 48.1285 174.256 48.1285H172.579V48.7763H174.79C180.015 48.7763 183.751 52.0557 183.751 56.6309C183.751 60.9781 180.281 63.8758 175.057 63.8758H170.291Z"
          fill="#FFBA80"
        />
        <path
          d="M147.55 64.1808C139.695 64.1808 135.501 59.2623 135.501 50.1491C135.501 41.9506 139.619 37.5275 147.245 37.5275C155.024 37.5275 159.219 42.4466 159.219 51.5597C159.219 59.7576 155.138 64.1808 147.55 64.1808ZM147.398 64.867C156.664 64.867 162.879 59.2623 162.879 50.8353C162.879 42.4466 156.664 36.8413 147.398 36.8413C138.055 36.8413 131.84 42.4466 131.84 50.8353C131.84 59.2623 138.055 64.867 147.398 64.867Z"
          fill="#FFBA80"
        />
        <path
          d="M106.483 64.8622H118.265C124.748 64.8622 129.82 61.3974 129.82 56.6308C129.82 52.7419 126.045 48.738 120.63 48.3947V48.2427C124.481 47.7468 126.654 43.8542 126.654 41.6815C126.654 38.2106 122.645 34.4937 116.702 34.4937H106.756V34.8589L108.022 34.8607C109.796 34.8619 110.181 35.0655 110.181 36.89V62.1601C110.181 63.987 109.95 64.1809 107.932 64.1809H106.483V64.8622ZM112.736 63.8758V63.7998C113.232 63.3422 113.423 62.732 113.423 61.855V37.2334C113.423 36.3558 113.232 35.6763 112.736 35.2181V35.1798H116.13C120.325 35.1798 123.07 38.6683 123.07 41.7568C123.07 44.77 120.4 48.1285 116.702 48.1285H115.025V48.7763H117.236C122.46 48.7763 126.197 52.0557 126.197 56.6308C126.197 60.9781 122.727 63.8758 117.503 63.8758H112.736Z"
          fill="#FFBA80"
        />
        <path
          d="M79.7865 64.8569H88.1741V64.2109H86.5562C85.0229 64.2109 84.3848 63.966 84.3848 63.3108C84.3848 62.8198 84.7257 62.0838 85.3644 61.0202L91.2395 50.9158L96.7324 61.1837C97.2854 62.0425 97.6264 62.6976 97.6264 63.1875C97.6264 63.8833 96.9019 64.2109 95.1565 64.2109H94.1774V64.8983H105.034V64.2109H104.225C102.735 64.2109 102.352 63.4336 100.819 61.0202L94.0498 48.3797L98.7118 40.8856C100.33 38.2674 100.969 37.6949 102.373 37.6949H102.927V37.3266H95.0927V37.6949H96.753C98.2438 37.6949 98.8394 37.9404 98.8394 38.5536C98.8394 39.0447 98.4991 39.7813 97.8178 40.8041L93.5812 47.5611L89.6953 40.0141C89.1416 38.9918 87.9923 37.969 87.3111 37.7648V37.6827H89.866V37.3145H81.8188V37.6827H82.3719C83.6494 37.6827 84.5865 38.2552 85.9916 40.874L90.8135 50.1385L84.4273 61.0609C82.852 63.6791 82.1282 64.2929 80.2126 64.2929H79.7865V64.8569Z"
          fill="#FFBA80"
        />
        <path
          d="M0.846558 64.8532H13.7733C20.8858 64.8532 26.4497 61.0901 26.4497 55.8604C26.4497 51.5934 22.3085 47.2006 16.3678 46.8238V46.6573C20.5929 46.1134 22.9771 43.1846 22.9771 40.7998C22.9771 36.9929 18.5849 34.4829 12.0583 34.4829H0.846558V34.8591H2.43642C4.65348 34.8591 4.90448 35.0688 4.90448 37.0768V61.9263C4.90448 63.9343 4.65348 64.1434 2.43642 64.1434H0.846558V64.8532ZM7.70802 63.8091V63.7253C8.25135 63.2233 8.46102 62.5541 8.46102 61.5921V37.4536C8.46102 36.4909 8.25135 35.7798 7.70802 35.2778V35.2359H11.4311C16.0323 35.2359 19.0449 37.4949 19.0449 40.8837C19.0449 44.1886 16.1168 46.5315 12.0583 46.5315H10.218V47.2426H12.6441C18.3758 47.2426 22.4751 50.8404 22.4751 55.8604C22.4751 60.6294 18.6681 63.8091 12.9371 63.8091H7.70802Z"
          fill="#FFBA80"
        />
        <path
          d="M142.091 52.1629H153.452V51.5728H151.91C149.767 51.5728 149.475 51.4105 149.475 49.4627V5.54691C149.475 3.59908 149.76 3.43742 151.91 3.43742H153.452V2.99072H142.091V3.35598H143.633C145.783 3.35598 145.986 3.52493 145.986 5.46608V49.4627C145.986 51.4032 145.777 51.5728 143.633 51.5728H142.091V52.1629Z"
          fill="#FFBA80"
        />
        <path
          d="M119.817 32.165H130.612C137.428 32.165 141.973 29.1627 141.973 24.6587C141.973 20.1547 137.428 17.1518 130.612 17.1518H127.203V5.54691C127.203 3.59908 127.486 3.35598 129.637 3.35598H131.179V2.99072H119.817V3.35598H121.36C123.51 3.35598 123.754 3.51825 123.754 5.46608V29.6088C123.754 31.5567 123.51 31.759 121.36 31.759H119.817V32.165ZM127.203 17.8422H129.759C135.196 17.8422 138.118 20.2355 138.118 24.6587C138.118 29.0813 135.196 31.4351 129.759 31.4351H126.473V31.3537C127 30.8669 127.203 30.2178 127.203 29.2843V17.8422Z"
          fill="#FFBA80"
        />
        <path
          d="M95.8398 34.8591H106.756V34.4939H105.66C103.51 34.4939 103.225 34.2915 103.225 32.3437V20.8038H107.08C113.816 20.8038 118.279 17.2333 118.279 11.9173C118.279 6.56124 113.816 2.99072 107.08 2.99072H95.8398V3.35598H97.3823C99.5325 3.35598 99.7762 3.55957 99.7762 5.50679V32.3437C99.7762 34.2909 99.5319 34.4939 97.3823 34.4939H95.8398V34.8591ZM102.495 3.76195H105.741C111.381 3.76195 114.383 6.60196 114.383 11.9173C114.383 17.2333 111.381 20.0733 105.741 20.0733H103.225V6.48041C103.225 5.54691 103.022 4.61401 102.495 3.96433V3.76195Z"
          fill="#FFBA80"
        />
        <path
          d="M34.5417 30.9517C37.6546 28.1202 41.3886 24.4786 41.4086 15.1503L41.4378 1.46383H50.9047C49.5974 1.98893 48.9848 3.83952 48.9781 4.78943L48.7745 30.9517H34.5417ZM25.5422 30.9517L26.562 38.9819H27.524L27.2694 34.4049C27.1545 32.3362 27.5283 31.7284 29.8165 31.7284H52.6264C54.9146 31.7284 55.4075 32.2365 55.4032 34.5314C55.4002 36.0325 55.1771 37.4984 54.7067 38.9819H55.8809C56.9141 36.4944 57.2854 33.9431 56.9007 30.9517H52.8944L52.6696 4.20295C52.6526 2.13053 52.9285 1.07548 55.2166 1.07548H56.9007V0.686523H34.49V1.07548H35.5262C38.3997 1.07548 39.442 0.930837 39.4936 3.36973L39.6899 12.5248C39.8832 21.5505 34.1716 27.5149 28.3567 30.9517H25.5422Z"
          fill="#FFBA80"
        />
        <path
          d="M41.4681 39.3025C34.871 39.3025 31.3248 42.3528 31.3248 48.0723C31.3248 53.9444 35.367 57.1095 42.8787 57.1095C49.4758 57.1095 53.022 54.0593 53.022 48.3391C53.022 42.4288 48.9799 39.3025 41.4681 39.3025ZM53.7082 48.2632C53.7082 55.7749 49.0941 60.8083 42.1543 60.8083C35.2527 60.8083 30.6387 55.7749 30.6387 48.2632C30.6387 40.6754 35.2527 35.6421 42.1543 35.6421C49.0941 35.6421 53.7082 40.6754 53.7082 48.2632Z"
          fill="#FFBA80"
        />
        <path
          d="M73.3669 31.7803C65.2511 31.7803 60.9179 26.6983 60.9179 17.2831C60.9179 8.81172 65.1727 4.24147 73.0521 4.24147C81.0896 4.24147 85.4234 9.32466 85.4234 18.7411C85.4234 27.2107 81.2075 31.7803 73.3669 31.7803ZM93.2889 34.9066V34.5413H91.747C89.5968 34.5413 89.3124 34.3796 89.3124 32.4318V5.54691C89.3124 3.59847 89.5968 3.43742 91.747 3.43742H93.2889V2.99072H83.3656V3.35598H83.949C85.1992 3.35598 85.8227 3.52007 85.8227 5.46608V8.54796C83.0155 5.37917 78.6154 3.53223 73.2101 3.53223C63.5573 3.53223 57.1358 9.32466 57.1358 17.9923C57.1358 26.6983 63.5573 32.4902 73.2101 32.4902C78.6154 32.4902 83.0155 30.6432 85.8227 27.4689V32.4318C85.8227 36.3196 88.0908 34.9066 93.2889 34.9066Z"
          fill="#FFBA80"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M68.0623 37.5712C61.6542 37.5712 62.8138 43.0409 62.8138 46.4188V52.0259C62.8138 60.9834 55.4546 66.9728 44.4349 66.9728C38.6389 66.9728 33.2244 65.0286 31.2796 61.3681L31.5847 61.1706C33.4912 64.6785 38.7148 66.4331 43.7865 66.4331C53.2436 66.4331 59.535 61.2916 59.535 53.5124V45.7709C59.535 42.4642 59.3679 37.2029 67.3895 37.1701L78.5131 37.1245V37.5712H76.9713C74.8265 37.5712 74.5366 37.2625 74.5366 39.2042V62.1594C74.5366 64.1018 74.8277 64.2701 76.9713 64.2701H78.5131V64.8603H67.1519V64.2701H68.6932C70.8349 64.2701 71.047 64.0981 71.047 62.1594V41.0287C71.047 39.7621 71.6438 38.103 72.6617 37.5712C70.8233 37.5712 69.4674 37.5712 68.0623 37.5712Z"
          fill="#FFBA80"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72.5515 13.644H73.4351C73.4351 15.8313 75.2249 17.6211 77.4128 17.6211V18.5048C75.2249 18.5048 73.4351 20.2946 73.4351 22.4819H72.5515C72.5515 20.2946 70.7617 18.5048 68.5744 18.5048V17.6211C70.7617 17.6211 72.5515 15.8313 72.5515 13.644Z"
          fill="#FFBA80"
        />
      </svg>
    </div>
  );
}
